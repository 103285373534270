import React from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { useDidUpdate } from 'common/hooks';
import { useGroupsSettings } from 'common/hooks/settings/useGroupsSettings';
import { useController } from 'common/context/controller';
import { LayoutType } from 'settings/consts';

import { useShadowStyles } from 'common/hooks/useShadowStyles';

import { GroupMembershipDialogs } from 'Group/Widget/GroupMembership';

import { GroupsSection, GroupsSectionRenderProps } from './GroupsSection';
import { ListLayout } from './ListLayout/ListLayout';
import { SideBarLayout } from './SideBarLayout/SideBarLayout';

import { classes, st } from './Groups.st.css';

export const Groups: React.FC = () => {
  const groupsSettings = useGroupsSettings();
  const { isMobile } = useEnvironment();
  const shadowStyles = useShadowStyles();
  const { centralFeed$ } = useController();

  useDidUpdate(() => {
    if (groupsSettings.groupListLayout === LayoutType.sideBar) {
      centralFeed$.fetch();
    }
  }, [groupsSettings.groupListLayout]);

  return (
    <div
      data-hook="groups"
      className={st(classes.root, { isMobile })}
      ref={shadowStyles.onRefChange}
    >
      <GroupsSection>{renderLayout}</GroupsSection>
      <GroupMembershipDialogs />
    </div>
  );

  function renderLayout({ openCreateGroupModal }: GroupsSectionRenderProps) {
    switch (groupsSettings.groupListLayout) {
      case LayoutType.sideBar:
        return <SideBarLayout onCreateGroupClick={openCreateGroupModal} />;
      default:
        return <ListLayout onCreateGroupClick={openCreateGroupModal} />;
    }
  }
};
