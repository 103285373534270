import React, { CSSProperties } from 'react';
import { Text, TextProps } from 'wix-ui-tpa';

import { st, classes } from './Header.st.css';

interface HeaderProps extends TextProps {
  textAlign?: CSSProperties['textAlign'];
}

/**
 * Header
 *
 * Component for header text
 */
export const Header: React.FC<HeaderProps> = ({
  className,
  textAlign,
  ...restProps
}) => (
  <Text
    tagName="h2"
    {...restProps}
    className={st(classes.root, { align: textAlign || '' }, className)}
  />
);

Header.displayName = 'Header';
