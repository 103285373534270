import React from 'react';
import { Components } from 'react-virtuoso';
import { TransitionGroup } from 'react-transition-group';

export const List: Components['List'] = React.forwardRef((props, ref) => {
  return (
    <div {...props} ref={ref}>
      {/* Exit animation should not be present otherwise infinite scroll will have fucked up animations */}
      <TransitionGroup exit={false} component={null}>
        {props.children}
      </TransitionGroup>
    </div>
  );
});
