import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';

import type { IGroup } from 'store/groups/types';

interface GroupPrivacyStatusProps {
  privacyStatus: IGroup['privacyStatus'];
}

export const GroupPrivacyStatus: React.FC<GroupPrivacyStatusProps> = ({
  privacyStatus,
}) => {
  const { t } = useTranslation();
  return (
    <>{t(`groups-web.group-list.privacy.${privacyStatus?.toLowerCase()}`)}</>
  );
};
