import { IFeedItem } from 'store/feed/types';

import { List } from './List';
import { Item } from './Item';
import { Footer } from './Footer';
import { ScrollSeekPlaceholder } from './ScrollSeekPlaceholder';

export const components = {
  Footer,
  Item,
  List,
  ScrollSeekPlaceholder,
};

export const computeItemKey = (_: number, item: IFeedItem) =>
  item.feedItemId as string;
