import React from 'react';
import { DropdownOptionProps } from 'wix-ui-tpa';
import { GroupsSortBy as V1GroupsSortBy } from '@wix/ambassador-social-groups-v1-group/types';

import { groupsChoseGroupsSortBy } from '@wix/bi-logger-groups/v2';
import { useBi, useErrorMonitor, useTranslation } from '@wix/yoshi-flow-editor';

import { LabeledDropdown } from '../../../../common/components/LabeledDropdown/LabeledDropdown';
import { BIUserEntry } from '../../../../common/bi-logger/types';

import { AppRootActions } from '../App/types';
import { DATA_HOOKS } from './dataHooks';

interface SortingBoxProps {
  onChange: AppRootActions['sortGroups'];
  initialSelectedId: string;
  className?: string;
  mobile?: boolean;
}

const sortByKeys = {
  [V1GroupsSortBy.RECENT_ACTIVITY]:
    'groups-web.group-list.sort.recent-activity',
  [V1GroupsSortBy.NAME]: 'groups-web.group-list.sort.alphabetical',
  [V1GroupsSortBy.MEMBERS_COUNT]: 'groups-web.group-list.sort.member-count',
} as const;

export const SortBy: React.FC<SortingBoxProps> = (props) => {
  const { t } = useTranslation();
  const bi = useBi();
  const errorMonitor = useErrorMonitor();

  const onChange = (option: DropdownOptionProps) => {
    try {
      bi.report(
        groupsChoseGroupsSortBy({
          sort_by: option.id,
          origin: 'popup_menu',
          userEntry: BIUserEntry.SITE,
        }),
      );

      props.onChange(option.id as V1GroupsSortBy);
    } catch (e: any) {
      console.warn('No `onChange` in props');
      errorMonitor.captureException(e);
    }
  };

  const options = React.useMemo(() => {
    return Object.entries(sortByKeys).map(([id, value]) => ({
      id,
      value: t(value),
      isSelectable: true,
    }));
  }, [sortByKeys, t]);

  // dropdown is not updated on props.initialSelectedId https://github.com/wix/wix-ui-tpa/pull/356
  return (
    <LabeledDropdown
      label={t('groups-web.group-list.sort')}
      options={options}
      data-hook={DATA_HOOKS.sortDropdown}
      onChange={onChange}
      initialSelectedId={props.initialSelectedId}
      className={props.className}
    />
  );
};
