import { useContext } from 'react';
import { MemberInvitesContext } from './MemberInvitesContext';

export function useMemberInvites() {
  const context = useContext(MemberInvitesContext);

  if (!context) {
    throw new Error(
      '`useMemberInvites()` must be used within a `MemberInvitesProvider`',
    );
  }

  return context;
}
