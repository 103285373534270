import React from 'react';

interface ArrowDownProps {}

export const ArrowDown: React.FC<ArrowDownProps> = (props) => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" {...props}>
      <g
        id="09-Icons-/-Arrow-/-Down"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <polygon
          fill="currentColor"
          transform="translate(12.000000, 12.000000) scale(1, -1) translate(-12.000000, -12.000000)"
          id="path-1"
          points="11.9994992 9 6 14.2409396 6.65704031 15 11.9994992 10.3338926 17.3429597 15 18 14.2409396"
        ></polygon>
      </g>
    </svg>
  );
};

ArrowDown.displayName = 'ArrowDown';
