export const DATA_HOOKS = {
  createdBy: 'createdBy',
};
export const GROUP_REQUESTS = 'group-requests';
export const REJECT_GROUP_REQUEST = 'reject-button';
export const APPROVE_GROUP_REQUEST = 'approve-button';
export const DECLINE_GROUP_REQUEST_MODAL = 'declineGroupRequestModal';
export const VIEW_GROUP_REQUEST = 'view-button';
export const WITHDRAW_GROUP_REQUEST = 'withdraw-button';
export const DELETE_GROUP_REQUEST_MODAL = 'deleteGroupRequestModal';
