import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { EFilterKeys } from 'api/feed/constants';
import { useCurrentStateAndParams } from 'common/router';
import { EmptyState } from 'common/components/EmptyState';

import { st, classes } from './EmptyResults.st.css';

interface IEmptyResultsProps {
  onCreatePost(): void;
  className?: string;
}

export function EmptyResults(props: IEmptyResultsProps) {
  const { t } = useTranslation();

  const { params } = useCurrentStateAndParams();

  if (params[EFilterKeys.TOPICS]) {
    return (
      <EmptyState
        className={st(classes.root, {}, props.className)}
        title={t('groups-web.discussion.filters.empty-result.title')}
        content={t('groups-web.discussion.filters.empty-result.subtitle')}
        button={{
          label: t('groups-web.discussion.filters.empty-result.button'),
          onClick: props.onCreatePost,
        }}
        boxProps={{
          withSideBorders: true,
        }}
      />
    );
  }

  return (
    <EmptyState
      className={st(classes.root, {}, props.className)}
      title={t('groups-web.discussion.first.title')}
      content={t('groups-web.discussion.first.description')}
      button={{
        label: t('groups-web.discussion.first.button'),
        onClick: props.onCreatePost,
      }}
      boxProps={{
        withSideBorders: true,
      }}
    />
  );
}
