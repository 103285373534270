import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import { useTranslation } from '@wix/yoshi-flow-editor';

import { NoGroups } from 'common/components/GroupList/NoGroups';

import { GroupCard, GroupCardSkeleton } from '../../GroupCard';
import { IGroup } from 'api/groups/types';

import dataHooks from './dataHooks';

type JoinedGroupsProps = {
  query: string;
  loading: boolean;
  groups: IGroup[];
  total: number | null | undefined;

  loadMore(): void;
  onSelect(group: IGroup): void;
};

export const JoinedGroups: React.FC<JoinedGroupsProps> = ({
  onSelect,
  loading,
  loadMore,
  groups,
  total,
}) => {
  const { t } = useTranslation();

  if (loading && !groups.length) {
    return (
      <>
        <GroupCardSkeleton />
        <GroupCardSkeleton />
        <GroupCardSkeleton />
        <GroupCardSkeleton />
        <GroupCardSkeleton />
      </>
    );
  }

  if (!groups.length) {
    return (
      <NoGroups
        emptyStateHeader={t('groups-web.search.no-results.title')}
        emptyStateText={t('groups-web.search.no-results.text')}
      />
    );
  }

  return (
    <InfiniteScroll
      initialLoad={false}
      useWindow={false}
      hasMore={!!total && total > groups.length}
      loadMore={loadMore}
      threshold={200}
      loader={
        <>
          <GroupCardSkeleton />
          <GroupCardSkeleton />
          <GroupCardSkeleton />
        </>
      }
    >
      {groups.map((group) => (
        <GroupCard
          data-hook={dataHooks.groupCard}
          group={group}
          key={group.id}
          onClick={handleGroupClick(group)}
        />
      ))}
    </InfiniteScroll>
  );

  function handleGroupClick(group: IGroup) {
    return () => {
      onSelect(group);
    };
  }
};
