import React from 'react';
import classnames from 'classnames';
import type { GroupResponse as ApiTypesV1GroupResponse } from '@wix/ambassador-social-groups-v1-group/types';
import { GroupWrapper } from '@wix/social-groups-api';

import { UISref } from 'common/router';

import { GroupPrivacyLevel } from './GroupPrivacy';
import { classes, st } from './GroupCardMeta.st.css';
import { GROUP_TITLE_LINK } from './dataHooks';
import { isUndefinedOrTrue } from '../../../utils/utils';
import { MembersLabelWithCount } from '../../MembersLabel/MembersLabelWithCount';
import { RoleBadges } from '../../RoleBadges';
import {
  classes as badgesClasses,
  st as badgesSt,
} from '../../RoleBadges/GroupsListBadge.st.css';
import { useSettings } from '@wix/tpa-settings/react';
import settingsParams from 'Groups/settingsParams';
import { groupsListWidgetSettingsParams as widgetSettingsParams } from 'settings/groups-list-widget';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { TPAComponentProps } from 'wix-ui-tpa/dist/src/types';

interface GroupCardMetaProps extends TPAComponentProps {
  group: ApiTypesV1GroupResponse;
  groupUrl: string;
  withAdminBadge?: boolean;
  alwaysShowMemberRequests?: boolean;
  truncate?: boolean;
}

export const GroupCardMeta: React.FC<GroupCardMetaProps> = ({
  group,
  groupUrl,
  className,
  withAdminBadge,
  alwaysShowMemberRequests,
  truncate,
}) => {
  const { relationship, roles } = group;
  const groupWrapper = new GroupWrapper(group);
  const { isMobile } = useEnvironment();
  const memberCount = groupWrapper.getMembersCount();
  const pendingMembersCount = groupWrapper.getPendingMembersCount();
  const membersName = groupWrapper.getMembersName();
  const privacyLevel = groupWrapper.getPrivacy();

  const settings = useSettings();

  const showMembersCount = settings.get(settingsParams.showMemberCount);
  // TODO: widgetSettingsParams?
  const groupType = isUndefinedOrTrue(
    settings.get(widgetSettingsParams.showGroupType),
  );

  const { t } = useTranslation();
  return (
    <div
      className={st(
        classes.root,
        {
          mobile: isMobile,
          truncate: !!truncate,
        },
        classnames(classes.meta, className),
      )}
    >
      <div className={classes.header}>{renderTitleLink()}</div>
      <p className={classes.details}>{renderMembersLabelWithCount()}</p>
    </div>
  );

  function renderMembersLabelWithCount() {
    if (alwaysShowMemberRequests && pendingMembersCount) {
      return renderPendingMembersCount();
    }
    return (
      <>
        {groupType && <GroupPrivacyLevel t={t} privacyLevel={privacyLevel} />}
        {showMembersCount && groupType && <span>&nbsp;&middot;&nbsp;</span>}
        {showMembersCount ? renderMembersCount() : null}
        {showMembersCount && pendingMembersCount ? (
          <>
            <span>&nbsp;&middot;&nbsp;</span>
            {renderPendingMembersCount()}
          </>
        ) : null}
      </>
    );
  }

  function renderMembersCount() {
    return (
      <MembersLabelWithCount
        groupMemberLabel={membersName}
        count={memberCount}
      />
    );
  }

  function renderPendingMembersCount() {
    return (
      <UISref
        state="group.members"
        params={{ slug: groupWrapper.getSlug(), expandJoinedRequests: true }}
      >
        <a className={classes.pendingMembers} onClick={handleLinkClick}>
          {t('groups-web.group-list.pending-members.count_icu', {
            count: pendingMembersCount,
          })}
        </a>
      </UISref>
    );
  }

  function handleLinkClick(e: React.MouseEvent) {
    e.stopPropagation();
  }

  function renderTitleLink() {
    return (
      <>
        <a
          data-hook={GROUP_TITLE_LINK}
          className={classes.link}
          href={groupUrl}
          data-bi-origin="group_title"
        >
          {group.details?.title}
        </a>
        {withAdminBadge && (
          <RoleBadges
            relationship={relationship!}
            roles={roles!}
            getBadgeClassName={(isAdmin) =>
              badgesSt(badgesClasses.badge, { isAdmin })
            }
          />
        )}
      </>
    );
  }
};
