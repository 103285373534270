import React from 'react';
import {
  ThumbnailImage,
  ImageResizeOptions,
  ThumbnailImageProps,
} from 'wix-ui-tpa';

import type { IGroup } from 'store/groups/types';

import { st, classes } from './GroupImage.st.css';
import cls from 'classnames';
import { skeleton } from 'common/components/Skeleton';

interface GroupImageProps extends ThumbnailImageProps {
  coverImage: IGroup['coverImage'];
  onClick?(): void;
  loading?: boolean;
}

export const GroupImage: React.FC<GroupImageProps> = ({
  coverImage,
  loading,
  onClick,
  ...restProps
}) => {
  const url = coverImage?.image?.mediaId || coverImage?.image?.fileUrl;

  return (
    <div
      onClick={onClick}
      className={st(classes.root, {
        empty: !url,
      })}
    >
      <div
        className={cls(classes.backgroundPlaceholder, loading ? skeleton : '')}
      >
        {url ? (
          <ThumbnailImage
            fluid
            src={url}
            aspectRatio="square"
            width={40}
            resize={ImageResizeOptions.cover}
            {...restProps}
          />
        ) : null}
      </div>
    </div>
  );
};
