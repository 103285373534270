import React from 'react';

import { classes } from './PendingGroupMeta.st.css';
import { DATA_HOOKS } from './dataHooks';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { GroupTitleLink } from '../../../../common/components/GroupCard/Bricks';
import { IGroupRequestProps } from 'Groups/Widget/PendingGroupList/IGroupRequestProps';

type Props = Omit<
  IGroupRequestProps,
  'groupRequestsActions' | 'openDialog' | 'goToGroup'
>;

export const PendingGroupMeta: React.FC<Props> = (props) => {
  const { groupRequest, groupUrl, createdBy, canResolvePendingGroups } = props;
  const { t } = useTranslation();
  const authorName = createdBy.getName(t('groups-web.member.anonymous'));
  const { privacyLevel, title } = groupRequest?.group!;
  const privacyLabel = `groups-web.group-list.privacy.${privacyLevel?.toLowerCase()}`;
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <GroupTitleLink title={title!} groupUrl={groupUrl} />
      </div>
      <p className={classes.details}>
        {canResolvePendingGroups ? (
          <>
            {t(privacyLabel)}
            &nbsp;&middot;&nbsp;
            <span
              data-hook={DATA_HOOKS.createdBy}
              data-author-name={authorName}
            >
              {t('groups-web.group-list.pending-groups.created-by', {
                authorName,
              })}
            </span>
          </>
        ) : (
          t('groups-web.group-list.pending-groups.status-for-creator')
        )}
      </p>
    </div>
  );
};

PendingGroupMeta.displayName = 'PendingGroupMeta';
