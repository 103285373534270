import React from 'react';
import { useSettings } from '@wix/tpa-settings/react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import type { GroupResponse as ApiTypesV1GroupResponse } from '@wix/ambassador-social-groups-v1-group/types';

import { groupAdapter } from '@wix/social-groups-api';

import { Header } from 'common/components/Text/Header';
import { CTA } from 'common/components/GroupCard/v2/CTA';
import { useGroupLabels } from 'common/components/GroupCard/v2/useGroupLabels';
import { useUser } from 'common/context/user/useUser';
import { useGroupWrapper } from 'common/hooks/useGroupWrapper';

import settingsParams from '../../../../../settingsParams';
import { GroupCard } from '../GroupCard/GroupCard';

import { classes, st } from './Groups.st.css';

type GroupMembershipButtonProps = {
  group: ApiTypesV1GroupResponse;
  onClick(): void;
};

// legacy membership button
const GroupMembershipButton: React.FC<GroupMembershipButtonProps> = ({
  group,
  onClick,
}) => {
  const g = useGroupWrapper(group);
  const { ctaPriority, ctaLabel } = useGroupLabels(g);
  const { userActions } = useUser();

  const onClickHandler = (event: any) => {
    event.stopPropagation();

    userActions.changeGroupMembership(
      groupAdapter(group) as any,
      undefined,
      'suggested_group',
    );

    onClick?.();
  };

  return (
    <CTA label={ctaLabel} priority={ctaPriority} onClick={onClickHandler} />
  );
};

type GroupsProps = {
  title: string;
  groups: ApiTypesV1GroupResponse[];
  withMembershipButton?: boolean;

  goToGroup?(groupId: string): void;
  onChangeMembership?(groupId: string): void;
};

export const Groups: React.FC<GroupsProps> = (props) => {
  const { isMobile } = useEnvironment();

  const settings = useSettings();
  const shouldShowImage = settings.get(settingsParams.showImage);

  return (
    <div className={st(classes.root, { mobile: isMobile })}>
      <Header
        textAlign={settings.get(settingsParams.headerAlignment)}
        className={classes.header}
      >
        {props.title}
      </Header>
      <div>
        {props.groups.map((group) => (
          <GroupCard
            group={group}
            goToGroup={props.goToGroup}
            withImage={shouldShowImage}
            action={
              props.withMembershipButton ? (
                <GroupMembershipButton
                  group={group}
                  onClick={() =>
                    props.onChangeMembership?.(group.groupId as string)
                  }
                />
              ) : null
            }
          />
        ))}
      </div>
    </div>
  );
};
