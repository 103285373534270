import React from 'react';
import { useSelector } from 'react-redux';
import { Avatar, Text, TextTypography, ButtonPriority } from 'wix-ui-tpa';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import type { DraftContent } from '@wix/ricos-common';
import { toPlainText, fromDraft } from 'ricos-content/libs/converters';

import { Button } from 'common/components/Button';
import { Box } from 'common/components/Box/Box';

import { selectCurrentUser } from 'store/application/selectors';

import { PluginsToolbar, IPluginsToolbarProps } from './PluginsToolbar';
import { NEW_POST_BOX } from './dataHooks';

import settingsParams from '../../../../settingsParams';

import { classes, st } from './CreatePost.st.css';

export interface ICreatePostProps {
  className?: string;
  draft?: DraftContent;

  onClick(): void;
  openPlugin?: IPluginsToolbarProps['onClick'];
}

export function CreatePost(props: ICreatePostProps) {
  const { draft, onClick, openPlugin } = props;

  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const settings = useSettings();
  const user = useSelector(selectCurrentUser);

  const placeholder = draft
    ? toPlainText(fromDraft(draft))
    : settings.get(settingsParams.newPostBoxLabel);

  return (
    <Box
      tabIndex={0}
      role="button"
      data-hook={NEW_POST_BOX}
      withSideBorders={!isMobile}
      onClick={onClick}
      aria-label={settings.get(settingsParams.newPostBoxLabel)}
      className={st(
        classes.root,
        {
          mobile: isMobile,
          hasDraft: !!draft,
        },
        props.className,
      )}
    >
      <div className={classes.createPostRow}>
        <Avatar src={user.imageUrl} className={classes.avatar} />
        <Text
          typography={TextTypography.runningText}
          className={classes.placeholder}
        >
          {placeholder}
        </Text>
        <Button className={classes.mainCTA} priority={ButtonPriority.primary}>
          {t('groups-web.discussion.new-post.cta')}
        </Button>
      </div>

      {!isMobile && (
        <PluginsToolbar
          onClick={openPlugin}
          className={classes.pluginsToolbar}
        />
      )}
    </Box>
  );
}

CreatePost.displayName = 'CreatePost';
