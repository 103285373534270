import React from 'react';
import { GroupPrivacyLevel as ApiGroupPrivacyLevel } from '@wix/ambassador-social-groups-v1-group/types';
import { WithTranslation } from '@wix/yoshi-flow-editor';

// @ts-expect-error
export const GroupPrivacyLevel: React.FC<
  Partial<WithTranslation> & {
    privacyLevel: ApiGroupPrivacyLevel;
  }
> = ({ t, privacyLevel }) => {
  return t?.(`groups-web.group-list.privacy.${privacyLevel.toLowerCase()}`);
};
