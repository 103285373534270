import React from 'react';

import * as api from 'api/groups/groups.api';
import type { IGroup } from 'api/groups/types';

import { useHttpClient } from 'common/hooks/useHttpClient';

export function useJoinedGroups() {
  const httpClient = useHttpClient();

  const [groups, setGroups] = React.useState<IGroup[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [total, setTotal] = React.useState<number | null | undefined>();

  return {
    groups,
    loading,
    total,
    queryGroups,
  };

  async function queryGroups(
    query: string,
    offset: number = 0,
    limit: number = 10,
  ) {
    setLoading(true);
    if (!offset) {
      setGroups([]);
      setTotal(null);
    }

    try {
      const { data } = await httpClient.request(
        api.getJoinedGroups({
          paging: {
            offset,
            limit,
          },
          filter: {
            lowerCaseTitle: { $contains: query.toLowerCase() },
          },
        }),
      );

      setGroups((prevGroups) => {
        if (offset) {
          return [...prevGroups, ...data.groups];
        }
        return data.groups;
      });
      setTotal(data.metadata?.total);
    } finally {
      setLoading(false);
    }
  }
}
