import React from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { Container } from 'wui/Container';

import { Groups } from '../Groups/Groups';

import { DATA_HOOKS } from './dataHooks';

export const App: React.FC = () => {
  const { isMobile } = useEnvironment();

  return (
    <Container fluid={isMobile} data-hook={DATA_HOOKS.root}>
      <Groups />
    </Container>
  );
};

App.displayName = 'App';
