import React, { FC } from 'react';
import { WidgetProps } from '@wix/yoshi-flow-editor';
import { WixCommentsApiProvider } from '@wix/comments-ooi-client';
import { useSettings, useStyles } from '@wix/tpa-settings/react';

import { SocialGroupStore } from 'store/SocialGroupsStore';

import { Router } from 'common/router';
import { WidgetContext } from 'common/context/widget/WidgetContext';
import { WidgetType } from 'common/context/widget/WidgetType';
import { ThemeProvider } from 'common/context/theme';
import { ControllerProvider } from 'common/context/controller';
import { Toasts } from 'common/components/Toasts';

import { IControllerProps } from '../../../controllers/types';

import settingsParams from '../settingsParams';
import stylesParams from '../stylesParams';

import AppRoot from './App/AppRoot';
import { GroupsWidgetWithStyles } from './App/types';

const Widget: FC<WidgetProps<GroupsWidgetWithStyles & IControllerProps>> = (
  props,
) => {
  const settings = useSettings();
  const styles = useStyles();

  return (
    <div data-hook="Groups-wrapper">
      <WidgetContext.Provider value={{ type: WidgetType.GroupsPage }}>
        <SocialGroupStore state={(props as any).store}>
          <ControllerProvider {...props}>
            <WixCommentsApiProvider {...(props as any)}>
              <Toasts />
              <Router>
                <ThemeProvider
                  host={props.host}
                  buttonType={settings.get(settingsParams.buttonType)}
                  buttonBorderRadius={styles.get(
                    stylesParams.buttonBorderRadius,
                  )}
                >
                  <AppRoot {...props} />
                </ThemeProvider>
              </Router>
            </WixCommentsApiProvider>
          </ControllerProvider>
        </SocialGroupStore>
      </WidgetContext.Provider>
    </div>
  );
};

export default Widget;
