import React from 'react';

import { AppToastsProvider } from 'common/components/AppToats/AppToastsContext';
import { MemberInvitesProvider } from 'common/context/member-invites/MemberInvitesContext';

import { GroupsAppProvider } from '../../contexts/GroupsAppProvider';

import type { GroupsWidgetWithStyles } from './types';

import { App } from './App';

export default function (props: GroupsWidgetWithStyles) {
  const { toasts, memberInvitesLink, loadMemberInvitesLink } = props;

  return (
    <GroupsAppProvider {...props}>
      <AppToastsProvider value={toasts}>
        <MemberInvitesProvider
          value={{ memberInvitesLink, loadMemberInvitesLink }}
        >
          <App />
        </MemberInvitesProvider>
      </AppToastsProvider>
    </GroupsAppProvider>
  );
}
