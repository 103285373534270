import React from 'react';

import { useTranslation } from '@wix/yoshi-flow-editor';
import { ButtonPriority, Text, TextTypography } from 'wix-ui-tpa';

import { Modal } from 'common/components/Modal/Modal';
import { BlackAndWhiteButton } from 'common/components/BlackAndWhiteButton';

import { st, classes } from './DeleteGroupRequestModal.st.css';

export interface DeclineGroupRequestModalProps {
  onConfirmGroupDeletion(e: any): void;
  onRequestClose(e: any): void;
}

export const DeleteGroupRequestModal: React.FC<
  DeclineGroupRequestModalProps
> = (props) => {
  const { onRequestClose, onConfirmGroupDeletion } = props;
  const { t } = useTranslation();

  return (
    <div className={st(classes.root)}>
      <Modal.Header title={t('groups-web.modal.delete-group-request.title')} />
      <Text
        typography={TextTypography.runningText}
        className={classes.subtitle}
      >
        {t('groups-web.modal.delete-group-request.subtitle')}
      </Text>
      <Modal.Buttons>
        <BlackAndWhiteButton
          priority={ButtonPriority.secondary}
          onClick={onRequestClose}
        >
          {t('groups-web.modal.decline-group.action.cancel')}
        </BlackAndWhiteButton>
        <BlackAndWhiteButton
          type="submit"
          priority={ButtonPriority.primary}
          onClick={onConfirmGroupDeletion}
        >
          {t('groups-web.modal.decline-group.action.delete')}
        </BlackAndWhiteButton>
      </Modal.Buttons>
    </div>
  );
};

DeleteGroupRequestModal.displayName = 'DeleteGroupRequestModal';
