import React from 'react';
import { classes } from './GroupTitleLink.st.css';

interface GroupTitleLinkProps {
  groupUrl: string;
  title: string;
}

export const GroupTitleLink: React.FC<GroupTitleLinkProps> = ({
  groupUrl,
  title,
}) => {
  return (
    <a data-hook="title-link" className={classes.link} href={groupUrl}>
      {title}
    </a>
  );
};
