import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { useController } from 'common/context/controller';

import { EmptyState, EmptyStateProps } from 'common/components/EmptyState';

interface IFeedItemErrorProps extends Partial<EmptyStateProps> {
  onRetry: () => void;
}

export const FeedItemError: React.FC<IFeedItemErrorProps> = (props) => {
  const { t } = useTranslation();
  const { feed$ } = useController();

  return (
    <EmptyState
      {...props}
      title={t('groups-web.discussion.error-state.title')}
      content={t('groups-web.discussion.error.feed-item.message')}
      button={{
        label: t('groups-web.discussion.error-state.cta'),
        onClick: props.onRetry,
      }}
    />
  );
};

FeedItemError.displayName = 'FeedItemError';
