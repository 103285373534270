import React from 'react';
import type { GroupResponse as ApiTypesV1GroupResponse } from '@wix/ambassador-social-groups-v1-group/types';
import { IconButton, ImageResizeOptions } from 'wix-ui-tpa';
import { ChevronRight } from '@wix/wix-ui-icons-common';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { useGroupWrapper } from 'common/hooks/useGroupWrapper';
import { GroupCardMedia } from 'common/components/GroupCard/media/GroupCardMedia';
import { GroupCardMeta } from 'common/components/GroupCard/meta/GroupCardMeta';
import { useHref } from 'common/router';

import { st, classes } from './GroupCard.st.css';

type GroupCardProps = {
  group: ApiTypesV1GroupResponse;
  goToGroup?(groupId: string): void;
  withImage: boolean;
  action?: React.ReactNode;
};

export const GroupCard: React.FC<GroupCardProps> = ({
  group,
  goToGroup,
  withImage,
  action,
}) => {
  const { isMobile } = useEnvironment();

  const g = useGroupWrapper(group);

  const groupUrl = useHref(
    'group',
    {
      slug: group.slug,
    },
    { absolute: true },
  );

  return (
    <div
      key={g.id}
      className={st(classes.root, { mobile: isMobile })}
      onClick={handleGroupClick}
    >
      {withImage ? (
        <div className={classes.groupImage}>
          <GroupCardMedia
            url={g.logo?.mediaId}
            groupUrl=""
            aspectRatio="square"
            width={40}
            resize={ImageResizeOptions.cover}
          />
        </div>
      ) : null}
      <GroupCardMeta
        group={group}
        groupUrl={groupUrl}
        alwaysShowMemberRequests={true}
        truncate={true}
        className={classes.meta}
      />
      <div className={classes.action}>
        {action ? (
          action
        ) : (
          <IconButton icon={<ChevronRight />} className={classes.icon} />
        )}
      </div>
    </div>
  );

  function handleGroupClick() {
    goToGroup?.(g.id);
  }
};
