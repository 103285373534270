import React from 'react';
import { useSettings } from '@wix/tpa-settings/react';
import { ButtonPriority, Text } from 'wix-ui-tpa';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { DATA_HOOKS } from 'common/components/GroupList/dataHooks';
import { NoGroups } from 'common/components/GroupList/NoGroups';
import { ModalV2 } from 'common/components/Modal';

import settingsParams from '../../../settingsParams';
import { useGroupsList } from '../../../contexts/useGroupsList';
import { GroupRequestsList } from '../../PendingGroupList/GroupRequestsList';
import { LayoutProps } from '../LayoutProps';
import { isApprovalStatusPending } from '../IsApprovalStatusPending';
import { CreateGroupButton } from '../CreateGroupButton';
import { CentralFeed } from './CentralFeed';
import { Header } from './Header';
import { SideBar } from './SideBar';

import { SIDEBAR_MODAL_DATA_HOOK } from './dataHooks';

import { st, classes } from './SideBarLayout.st.css';

type SideBarLayoutProps = LayoutProps;

export const SideBarLayout: React.FC<SideBarLayoutProps> = (props) => {
  const { t } = useTranslation();
  const { groups, groupNameQuery } = useGroupsList();
  const settings = useSettings();

  const { isMobile } = useEnvironment();

  const [isSideBarInModalOpened, setIsSideBarInModalOpened] =
    React.useState(false);

  const notPendingGroups = React.useMemo(() => {
    return groups.filter((g) => !isApprovalStatusPending(g));
  }, [groups]);

  if (groups && !groups.length && !groupNameQuery) {
    return (
      <NoGroups
        data-hook={DATA_HOOKS.noGroups}
        emptyStateHeader={t('groups-web.empty-state.header')}
        emptyStateText={t('groups-web.empty-state.text')}
        actionButton={
          <CreateGroupButton
            priority={ButtonPriority.primary}
            onClick={props.onCreateGroupClick}
            data-hook={DATA_HOOKS.noGroupsCreateButton}
            biOrigin="new_layout_any_empty"
          >
            {t('groups-web.empty-state.cta')}
          </CreateGroupButton>
        }
      />
    );
  }

  const shouldShowHeader =
    settings.get(settingsParams.showFeedTitle) || isMobile;

  return (
    <div
      className={st(classes.root, {
        alignment: settings.get(settingsParams.sideBarAlignment),
        mobile: isMobile,
        showHeader: shouldShowHeader,
      })}
    >
      {shouldShowHeader ? (
        <div className={classes.header}>
          <Header onClick={openSideBarInModal} />
        </div>
      ) : null}
      <div className={classes.main}>
        <GroupRequestsList />
        <CentralFeed />
      </div>
      {isMobile ? (
        <ModalV2
          isOpen={isSideBarInModalOpened}
          onClose={closeSideBarInModal}
          data-hook={SIDEBAR_MODAL_DATA_HOOK}
        >
          <ModalV2.Title>{t('groups-web.group-list.label')}</ModalV2.Title>
          <ModalV2.Content>
            <SideBar
              onCreateButtonClick={props.onCreateGroupClick}
              groups={notPendingGroups}
            />
          </ModalV2.Content>
        </ModalV2>
      ) : (
        <div className={classes.sidebar}>
          <SideBar
            groups={notPendingGroups}
            onCreateButtonClick={props.onCreateGroupClick}
          />
        </div>
      )}
    </div>
  );

  function openSideBarInModal() {
    setIsSideBarInModalOpened(true);
  }

  function closeSideBarInModal() {
    setIsSideBarInModalOpened(false);
  }
};
