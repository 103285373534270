import { createSelector } from 'reselect';

import { selectFeedPagination } from 'store/feed/selectors';
import { selectIsSiteAdmin } from 'store/application/selectors';
import { selectJoinedGroups } from 'store/groups/selectors';

export const selectHasActiveJoinedGroups = createSelector(
  selectJoinedGroups,
  (groups) =>
    groups.some((group) => group.membersCount && group.membersCount >= 10),
);

export const selectShouldShowInviteMembers = createSelector(
  selectIsSiteAdmin,
  selectHasActiveJoinedGroups,
  selectFeedPagination,
  (isSiteAdmin, hasActive, pagination) =>
    !pagination.nextCursor && !hasActive && isSiteAdmin,
);
